.app-about {
  display: grid;
  grid-template-columns: none;
  height: 100vh;
  overflow-x: hidden;

  @include for-normal-layout {
    grid-template-columns: 50% 50%;
  }

  .left_wrapper {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: unset;
    justify-content: center;

    @include for-narrow-layout {
      align-items: center;
    }

    .border_deisgn {
      position: absolute;
      height: 80%;
      width: 100%;
      border: 3px solid #353535;

      @include for-normal-layout {
        width: 30%;
      }

      @include for-wide-layout {
        width: 575px;
      }
    }
    

    .about-inner {
      margin-left: 0px;

      @include for-normal-layout {
        margin-left: 85px;
      }

      .about-header {
        font-size: 24px;
        margin-left: 14px;
        margin-bottom: 24px;
        background: #fff;
        color: #353535;
        position: relative;
        width: 60%;

        @include for-narrow-layout {
          width: 51%;
          margin-left: 40px;
        }
        @include for-normal-layout {
          width: 30%;
        }
      }
      .about-content {
        width: 80%;
        letter-spacing: 4px;
        margin-left: 14px;
        background: #fff;
        overflow: hidden;
        position: relative;
        font-size: 13px;

        @include for-narrow-layout {
          letter-spacing: 5px;
          width: 67%;
          font-size: 15px;
          margin-left: 40px;
        }
        @include for-normal-layout {
          width: 64%;
          font-size: 20px;
        }
      }
    }
  }

  .right_wrapper {
    display: none;
    overflow: hidden;
    // height: 80%;
    @include for-normal-layout {
      display: flex;
    }

    .img-holder {
      height: 80%;

      @include for-normal-layout {
        width: 400px;
        // height: 753px;
      }
      @include for-wide-layout {
        width: 609px;
      }
      img {
        width: 100%;
        // height: 100%;
        object-fit: cover;
      }
    }
  }
}
