* {
    box-sizing: border-box;
}

::selection {
    background-color: rgb(78, 78, 78);
    color: white;
    
}

html {
    font-family: 'montserrat', sans-serif;
    line-height: 1.6;
    color: $clr2;
    scroll-behavior: smooth;
}

body {
    margin: 0;
}

img {
    width: 100%;
    height: 100%;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0 0 0.4em;
}

h1 {
    font-size: rem(30px);
}

p {
    margin: 0 0 2em;
}

li{
    list-style: none;
}


h1{
    font-family: 'montserrat';

}

  

