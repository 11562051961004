.app-footer {
  font-family: "montserrat";
  display: grid;
  grid-template-areas:
    "top"
    "middle"
    "bottom";
  grid-template-columns: 1fr;
  overflow: hidden;
  height: 50vh;
  background: #2b2b2b;

  @include for-normal-layout {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "top top"
      "left right";
  }

  .contact-header {
    grid-area: top;
    border-bottom: 1px solid #eee;

    @include for-normal-layout {
      border:none
    }

    h1 {
      text-align: center;
      font-size: 30px;
      letter-spacing: 7px;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 10px;
      color: white;
      cursor: default;

      @include for-narrow-layout {
        margin-bottom: 20px;
      }
      @include for-normal-layout {
        font-size: 40px;
      }

    }
    p {
      text-align: center;
      font-size: 13px;
      letter-spacing: 7px;
      text-transform: uppercase;
      margin-bottom: 10px;
      padding: 0px 44px;
      color: white;
      cursor: default;

      @include for-narrow-layout {
        font-size: 16px;
        margin-bottom: 50px;
        padding: 0px 0px;
      }
      @include for-normal-layout {
        font-size: 20px;
      }
    }
  }

  .contact1 {
    letter-spacing: 3px;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    color: #969696;
    grid-area: middle;
    padding: 0px 44px;
    border-bottom: 1px solid #eee;
    
    @include for-narrow-layout {
      padding: 0 20%;
    }
    @include for-normal-layout {
      grid-area: left;
      border: none;
    }

    span {
      text-transform: uppercase;
    }
    h1 {
      font-size: 14px;
      text-align: center;
      margin-left: 50px;
      margin: 0;

      @include for-narrow-layout {
        font-size: 17px;
      }
      @include for-normal-layout {
        font-size: 20px;
        text-align: left;
      }

    }
    .links {
      margin-top: 10px;
      display: flex;

      @include for-normal-layout {
        display: unset;
      }

      .link:not(:first-child) {
        margin: 0px 10px;
      }
    }
  }
  .contact2 {
    grid-area: bottom;
    border:none;

    @include for-normal-layout {
      grid-area: right;
      border-left: 1px solid #eee;
    }

    a {

      button {
        font-size: 16px;
        padding: 12px;
        background: white;
        outline: none;
        border-radius: 100px;
        transition: 0.4s;
        border: 2px solid #454545;
        cursor: pointer;
        letter-spacing: 3px;
        text-transform: uppercase;

        @include for-normal-layout {
          padding: 20px;
        }
        
        &:hover {
          color: white;
          background-color: #454545;
          border: 2px solid white;

        }
      }
    }
  }
}



