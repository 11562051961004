.app-frontend-mentor {
  .header {
    background-color: #353535;    
    width: 100%;
    color: white;
    text-align: center;
    padding: 10px;
    font-size: 30px;
    margin-bottom: 30px;
    letter-spacing: 10px;

    @include for-narrow-layout {
      font-size: 40px;
    }
  }
  .content {
    // h1 {
    //   font-size: 25px;
    //   cursor: default;

    //   @include for-narrow-layout {
    //     font-size: 30px;
    //   }
    //   @include for-normal-layout {
    //     font-size: 40px;
    //   }
    // }
    p {
      font-size: 16px;
      cursor: default;

      br {
        display: none;
      }

      @include for-narrow-layout {
        font-size: 18px;
      }

      @include for-normal-layout {
        br {
          display: block;
        }
      }
    }
    .box-holder {
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(1, 1fr);

      @include for-normal-layout {
        grid-template-columns: repeat(2, 1fr);
      }

      @include for-wide-layout {
        grid-template-columns: repeat(3, 1fr);
      }

      .box-placer {
        padding: 0.75rem;
        overflow: hidden;
        .box {
          padding-top: 61.6666%;
          overflow: hidden;
          position: relative;
          border-radius: 12px;

          .img-holder {
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            .tags {
              width: 100%;
              height: 35%;
              background-color: rgba(76, 76, 76, 0.6);
              position: absolute;
              bottom: 0;
              .tag-holder {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                height: 100%;
                color: white;
                font-size: 14px;
                text-align: center;
                font-weight: 700;
                letter-spacing: 1px;
                .tag-one {
                  .tag-txt {
                    background-color: #5079ff;
                    width: 75%;
                    border-radius: 10px;
                  }
                }
                .tag-two {
                  .tag-txt {
                    background-color: #ff5050;
                    width: 75%;
                    border-radius: 10px;
                  }
                }
                
                .tag-three {
                  .tag-txt {
                    background-color: #3fca40;
                    width: 75%;
                    border-radius: 10px;
                  }
                }
                .tag-four {
                  .tag-txt {
                    background-color: #e077e4;
                    width: 75%;
                    border-radius: 10px;
                  }
                }
              }
            }
            img {
              // object-fit: cover;
            }
          }

          .btn-holder {
            position: relative;
            border-radius: 12px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .btn-box {
              width: 100%;
              height: 100%;
              .btn-box-holder {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 100%;
                background: #2b2b2b;
                p {
                  margin-bottom: 20px;
                  color: white;
                }

                button {
                  outline: none;
                  border: none;
                  border: 2px solid #454545;
                  color: #454545;
                  padding: 17px;
                  cursor: pointer;
                  letter-spacing: 3px;
                  background-color: white;
                  border-radius: 100px;
                  transition: 0.4s;
                  &:hover {
                    color: white;
                    background-color: #454545;
                    border: 2px solid white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
