
@font-face {
    font-family: montserrat;
    src: url(../../fonts/Montserrat/Montserrat-Regular.ttf);
}
@font-face {
    font-family: cinzel;
    src: url(../../fonts/Cinzel/Cinzel-Regular.ttf);
}
@font-face {
    font-family: arcivo;
    src: url(../../fonts/Archivo/ArchivoBlack-Regular.ttf);
}
@font-face {
    font-family: pattaya;
    src: url(../../fonts/Pattaya/Pattaya-Regular.ttf);
}
