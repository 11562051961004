.app-web-and-app {
    margin-bottom: 100px;
    // h1 {
    //     text-align: center;
    //     font-size: 25px;
    //     font-weight: bold;
    //     // @include for-narrow-layout {
    //     //     font-size: 40px;
    //     // }
    //     @include for-normal-layout {
    //         font-size: 40px;
    //     }
    // }
    .header {
        background-color: #353535;
        width: 100%;
        color: white;
        text-align: center;
        padding: 10px;
        font-size: 30px;
        margin-bottom: 30px;
        letter-spacing: 10px;

        @include for-narrow-layout {
            font-size: 40px;
        }
    }
    h2 {
        text-align: center;
        font-size: 25px;
        font-family: pattaya;
        @include for-normal-layout {
            font-size: 40px;
        }
    }
    p {
        text-align: center;
        font-size: 16px;
        margin: 0;
        margin-bottom: 10px;
        @include for-normal-layout {
            font-size: 18px;
        }
    }
    .img-holder {
        margin-bottom: 20px;
        img {
            width: 400px;
            height: 260px;
            object-fit: cover;
            @include for-narrow-layout {
                width: 500px;
                height: 360px;
            }
            @include for-normal-layout {
                width: 800px;
                height: 560px;
            }
        }
    }
}
