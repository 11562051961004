.app-projects {
  margin-top: 100px;
  // height: 510px;
  height: 610px;
  overflow: hidden;
  position: relative;

  // @include for-narrow-layout {
  //   height: 69vh;
  // }

  .heading {
    width: 100%;
    margin-left: 8px;
    font-size: 25px;
    color: #2b2b2b;
    letter-spacing: 10px;
    top: 26px;
    position: relative;
    cursor: default;

    @include for-narrow-layout {
      top: 34px;
      font-size: 35px;
      margin-left: 54px;
    }
    @include for-normal-layout {
      top: 46px;
      font-size: 55px;
      margin-left: 180px;
    }
  }
  .green {
    height: 15vh;
    background: #2b2b2b;
    margin-top: -40px;
    overflow: hidden;
    position: relative;
    bottom: -50px;

    .heading {
      position: relative;
      top: -24px;
      color: #fff;
      cursor: default;

      @include for-narrow-layout {
        top: -32px;
      }
      @include for-normal-layout {
        top: -54px;
      }
    }
  }

  .green2 {
    height: 13vh;
    background: #2b2b2b;
    @include for-narrow-layout {
      height: 14vh;
    }
    @include for-normal-layout {
      height: 13vh;
    }

    .projects-container {
      display: flex;
      justify-content: space-around;
      margin-bottom: 80px;

      .content {
        // height: 20vh;
        height: 230px;
        width: 30vw;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        border: 2px solid black;
        z-index: 1;
        border-radius: 10px;
        @include for-narrow-layout {
          // height: 26vh;
          height: 230px;

          width: 28vw;
        }
        @include for-normal-layout {
          // height: 25vh;
          height: 230px;

          // width: 15vw;
          width: 270px;
        }
        .project {
          height: 200%;
          width: 100%;
          overflow: hidden;
          position: relative;
          .img-wrapper {
            height: 50%;
            width: 100%;
            position: absolute;
            a {
              button {
                border: none;
                background-color: inherit;
                outline: none;
              }
            }
          }

          .details {
            height: 50%;
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title-pawesome {
              font-size: 15px;
              margin-left: 10px;
              color: rgb(252, 123, 2);
              cursor: default;

              @include for-narrow-layout {
                font-size: 17px;
              }
              @include for-normal-layout {
                font-size: 19px;
              }
            }

            .title-apexgram {
              font-family: pattaya;
              font-size: 15px;
              margin-left: 10px;
              color: rgb(15, 15, 15);
              cursor: default;
              @include for-narrow-layout {
                font-size: 17px;
              }
              @include for-normal-layout {
                font-size: 19px;
              }
            }

            .title-appsus {
              font-family: arcivo;
              font-size: 15px;
              margin-left: 10px;
              color: rgb(48, 120, 145);
              cursor: default;
              @include for-narrow-layout {
                font-size: 17px;
              }
              @include for-normal-layout {
                font-size: 19px;
              }
            }






            .tags {
              padding: 0px 0px 5px 0px;
              .tag-holder {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                height: 100%;
                color: white;
                // font-size: 11px;
                font-size: 9px;
                text-align: center;
                letter-spacing: 1px;
                .tag-one {
                  .tag-txt {
                    background-color: #454545;
                    // width: 75%;
                    width: 90%;
                    border-radius: 10px;
                    margin: 3px 0px;
                    padding: 2px 0px;
                  }
                }
              }
            }


            
            







            .para {
              font-size: 9px;
              margin: 0px 10px 0px 10px;
              color: rgb(109, 109, 109);
              cursor: default;
              overflow: scroll;
              overflow-x: hidden;
              @include for-narrow-layout {
                font-size: 11px;
                margin: 0px 10px 4px 10px;
              }
              @include for-normal-layout {
                font-size: 12px;
                margin: 0px 10px 10px 10px;
              }
            }
            .para::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: #f5f5f5;
            }

            .para::-webkit-scrollbar {
              width: 4px;
              background-color: #f5f5f5;
            }

            .para::-webkit-scrollbar-thumb {
              background-color: #000000;
            }

            .btn {
              margin-bottom: 10px;
              a {
                button {
                  background: inherit;
                  outline: none;
                  border: none;
                  border-radius: 100px;
                  transition: 0.4s;
                  border: 2px solid #454545;
                  color: #454545;
                  cursor: pointer;
                  letter-spacing: 3px;
                  padding: 4px;
                  font-size: 7px;

                  @include for-narrow-layout {
                    padding: 6px;
                    font-size: 10px;
                  }
                  @include for-normal-layout {
                    padding: 8px;
                    font-size: 12px;
                  }

                  &:hover {
                    color: white;
                    background-color: #454545;
                  }
                }
              }
            }
          }
        }
        .project-soon {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .details-soon {
            cursor: default;
            font-size: 23px;
            text-align: center;
            color: #a7a7a7;
            line-height: 26px;

            @include for-narrow-layout {
              font-size: 40px;
              line-height: 45px;
            }
            @include for-normal-layout {
              font-size: 41px;
              line-height: 45px;
            }
          }
        }
      }
    }
    .btn-all-proj {
      a {
        button {
          font-size: 18px;
          background: inherit;
          outline: none;
          border: none;
          border-radius: 100px;
          transition: 0.4s;
          border: 2px solid #454545;
          color: #454545;
          padding: 17px;
          cursor: pointer;
          letter-spacing: 3px;
          font-family: "montserrat";

          @include for-narrow-layout {
            font-size: 22px;
          }

          &:hover {
            color: white;
            background-color: #454545;
          }
        }
      }
    }
  }
}
