.hero-container {
  height: 100vh;
  
  .loader {
    height: 100vh;
  }
  .hero {
    background-image: url("../../img/1.png");
    background-size: cover;

    .text {
      font-size: 4rem;
      position: absolute;
      text-align: center;
      width: 100%;
      height: 100%;
    }

    .heroTwo {
      height: 100vh;
      background-image: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 2%,
          rgba(245, 245, 245, 0) 100%
        ),
        url("../../img/2.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      -webkit-perspective: 1000px;
      perspective: 1000px;

      svg {
        position: absolute;
      }

      .content {
        position: absolute;
        top: 0;
        display: flex;
        font-family: "cinzel";
        letter-spacing: 5px;
        margin-top: 30px;
        font-size: 16px;
      }
    }
  }
}

.father {
  .children {
    padding: 10px;
    border: 2px solid gray;
    margin: 10px;
    text-align: center;
    background-color: rgb(197, 151, 151);
  }
}
