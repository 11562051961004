.app-skills {
  .header {
    background-color: #353535;    
    width: 100%;
    color: white;
    text-align: center;
    padding: 10px;
    font-size: 30px;
    margin-bottom: 30px;
    letter-spacing: 10px;

    @include for-narrow-layout {
      font-size: 40px;
    }
  }
  .skills-container {
    flex-direction: column;
    box-shadow: 0 2px 5px 0 rgba(99, 99, 99, 0.16),
      0 2px 10px 0 rgba(43, 43, 43, 0.12);
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;

    @include for-normal-layout {
      width: 80%;
    }

    h1 {
      width: 96%;
      border-bottom: 1px solid #ddd;
      padding: 15px;
      color: #676767;
      text-align: center;
      cursor: default;
      @include for-normal-layout {
        width: 80%;
      }

    }
    .skills {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 80%;
      .skills-one {
        padding-bottom: 20px;
        p {
          font-size: 14px;
          cursor: default;

          @include for-narrow-layout {
            font-size: 16px;
          }
        }
        .skill-img {
          display: flex;
          justify-content: center;

          img {
            width: 70px;
            height: 70px;

            @include for-narrow-layout {
              width: 100px;
              height: 100px;
            }
          }
        }
        p {
          margin: 0;
          color: #4a4a4a;
        }
      }
    }
  }
}


